import { 
    getRoomDetailApiUrl, getServicesApiUrl, getServicesByCategoryApiUrl, getCategoriesByTypeApiUrl
 } from "./ApiUrls";
import { get } from "./Service";

export const getRoomDetail = async (tenantId, roomId) => {
    const response = await get(getRoomDetailApiUrl(tenantId, roomId));
    return response;
};

export const getCategories = async (tenantId, locationId) => {
    const response = await get(getCategoriesByTypeApiUrl(tenantId, locationId,"SERVICE"));
    return response;
};


export const getServices = async (tenantId, locationId) => {
    const response = await get(getServicesApiUrl(tenantId, locationId));
    return response;
};

export const getServicesByCategory = async (tenantId, locationId, categoryId) => {
    const response = await get(getServicesByCategoryApiUrl(tenantId, locationId, categoryId));
    return response;
};

