
export const getClassAssignmentsUrl = (instituteKey, classKey) => {
    return `/institute/${instituteKey}/class/${classKey}/assignments`
}

export const getClassQandAsUrl = (instituteKey, classKey) => {
    return `/institute/${instituteKey}/class/${classKey}/qas`
}

export const getExamUrl = (instituteKey, classKey) => {
    return `/institute/${instituteKey}/class/${classKey}/exam`
}

export const getAssignmentUrl = (instituteKey, classKey, assignmentKey) => {
    return `/institute/${instituteKey}/class/${classKey}/assignments/${assignmentKey}`
}

export const getDashboardUrl = (tenantId, roomId) => {
    return `/t/${tenantId}/r/${roomId}`
}

export const getTabNavigationUrl = (tenantId, roomId, url) => {
    return `/t/${tenantId}/r/${roomId}/${url}`
}

export const getHistoryUrl = (tenantId, roomId) => {
    return `/t/${tenantId}/r/${roomId}/service-history`
}

export const getRequestDetailUrl = (tenantId, roomId, id) => {
    return `/t/${tenantId}/r/${roomId}/service-request/${id}`
}
export const getProductRequestDetailUrl = (tenantId, roomId, id) => {
    return `/t/${tenantId}/r/${roomId}/product-request/${id}`
}

export const getExploreUrl = (tenantId, roomId) => {
    return `/t/${tenantId}/r/${roomId}/explore`
}

export const getChatUrl = (tenantId, roomId) => {
    return `/t/${tenantId}/r/${roomId}/chat`
}

export const getMoreUrl = (tenantId, roomId) => {
    return `/t/${tenantId}/r/${roomId}/more`
}



export const PROFILE = {
    "companyEmail": "",
    "currentProject": "",
    "description": "",
    "designation": "",
    "dob": "",
    "education": "",
    "emergencyContactNumber": "",
    "emergencyContactPerson": "",
    "identificationNumber": "",
    "identificationType": "",
    "institute": "",
    "mobile": "",
    "pan": "",
    "personalEmail": "",
    "profileImage": ""
};

export const USER = {
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    token: "",
    confirmToken: ""
}
