import React, { useState } from 'react'
import EdcCarousel from './carousel'
import CategoryCard from '../cards/category-card'

export default function Categories({list, onClick}) {

    const [selectedItem, setSelectedItem] = useState("all")
    const onViewAll = () => {

    }

    const handleClick = (item) => {
        setSelectedItem(item.id)
        if(onClick) {
            onClick(item)
        }
    }

    return (
        <EdcCarousel title="Categories" showViewAll onViewAll={onViewAll}>
            <CategoryCard selected={selectedItem === "all"} item={{name: "All", id: "all"}} onClick = {() => handleClick({name: "all", id: "all"})}  />
            {list?.map((item) => <CategoryCard selected={selectedItem === item.id} item={item} key={item.id} onClick = {() => handleClick(item)} />
            )}
        </EdcCarousel>
    )
}