import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VDiv from "../containers/vdiv";

import {
  getServiceRequests,
  // getServiceRequestStatuses,
  getServiceRequestByStatus,
} from "../../service/ServiceRequestService";
import // getRoomServices,
// getUserNumberServices,
"../../service/RoomService";

import ServiceRequestPopup from "../popups/service-request-popup";
import {
  getProductRequestDetailUrl,
  getRequestDetailUrl,
} from "../../Constant";
import ServiceRequestCard from "../cards/service-request-card";
// import Statuses from "./statuses";
import HSpacer from "../elements/h-spacer";
import Input from "../inputs/input";
import axios from "axios";
import { getProductRequestByPhoneNumber } from "../../service/ProductService";

export default function ServiceRequestHistory({ search }) {
  const { tenantId, roomId } = useParams();

  const [phoneNumber, setPhoneNumber] = useState("");

  const [list, setList] = useState([]);
  const [productRequestList, setProductRequestList] = useState([]);
  // const [services, setServices] = useState([]);
  const [showNoRequestsFound, setShowNoRequestsFound] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showRequests, setShowRequests] = useState(false);
  //eslint-disable-next-line
  const [selectedService, setSelectedService] = useState();

  const navigate = useNavigate();

  const formTitle = "Create Service Request";

  const locationId = localStorage.getItem("locationId");
  // const [statuses, setStatuses] = useState([]);
  // const [show, setShow] = useState(false);

  // const locationId = localStorage.getItem("locationId");

  const fetchServiceRequests = async (status) => {
    let response;
    if (status === "all") {
      response = await getServiceRequests(tenantId, roomId);
    } else {
      response = await getServiceRequestByStatus(tenantId, roomId, status);
    }
    setList(response);
  };
  const filteredServiceRequests = list?.filter((item) =>
    item?.serviceRequest.service?.name
      ?.toLowerCase()
      .includes(search.toLowerCase())
  );

  // const fetStatuses = async () => {
  //   const response = await getServiceRequestStatuses(tenantId, locationId);
  //   setStatuses(response);
  // };

  // const fetchServices = async () => {
  //   const response = await getRoomServices(tenantId, roomId);
  //   setServices(response);
  // };

  const productRequestsByNumber = (tenantId, locationId, phoneNumber) => {
    getProductRequestByPhoneNumber(tenantId, locationId, phoneNumber).then(
      (response) => {
        if (response) {
          if (response) {
            setProductRequestList(response);
            setShowNoRequestsFound(false);
          } else {
            setShowNoRequestsFound(true);
          }
        }
      }
    );

    // if(response.status === 200){

    //   if(response.data.list.length > 0 ){
    //     setProductRequestList(response.data.list);
    //     setShowNoRequestsFound(false)
    //   }    else{
    //     setShowNoRequestsFound(true)
    //   }

    //  }else{

    //   setProductRequestList([])
    //  }
  };
  const fetchServicesByNumber = async (tenantId, locationId, phoneNumber) => {
    const response = await axios.get(
      `https://dev-api-concierge.gjglobalsoft.com/v1/user/tenant/${tenantId}/location/${locationId}/service-request-history/by-phone/${phoneNumber}`
    );

    if (response.status === 200) {
      if (response.data.list.length > 0) {
        setList(response.data.list);
        setShowNoRequestsFound(false);
      } else {
        setShowNoRequestsFound(true);
      }
    } else {
      setList([]);
    }
  };

  // const openRequestForm = (event) => {
  //   event.stopPropagation();
  //   setSelectedService({ service: { id: "" } });
  //   setShowForm(true);
  // };

  const goToDetail = (item) => {
    navigate(getRequestDetailUrl(tenantId, roomId, item.serviceRequest.id));
  };

  const goToProductDetail = (item) => {
    navigate(
      getProductRequestDetailUrl(tenantId, roomId, item.productRequest.id)
    );
  };
  const closeRequestForm = (item) => {
    setShowForm(false);
  };

  const handleSave = () => {
    fetchServiceRequests("all");
    setShowForm(false);
  };

  // const onFilterClick = (item) => {
  //   fetchServiceRequests(item.id);
  // };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    if (e.target.value === "") {
      setList([]);
      setShowNoRequestsFound(false);
      setShowRequests(false)
    }
  };
  useEffect(() => {
    // fetchServiceRequests("all");
    // fetchServices();
    // productRequestsByNumber(tenantId,locationId,phoneNumber)
    //eslint-disable-next-line
  }, [roomId]);

  const data = [
    {
      id: "1",
      tabTitle: "Service Requests",
      tabContent: (
        <VDiv className={"row"}>
          {filteredServiceRequests.length > 0 && !showNoRequestsFound
            ? filteredServiceRequests.map((item) => {
                return (
                  <VDiv className="col-md-4 mb-3" key={item.id}>
                    <ServiceRequestCard
                      item={item}
                      onClick={() => goToDetail(item)}
                      showStatus={false}
                    />
                  </VDiv>
                );
              })
            : showNoRequestsFound && (
                <div>
                  <p>No Requests Found</p>
                </div>
              )}
        </VDiv>
      ),
    },
    {
      id: "2",
      tabTitle: "Product Requests",
      tabContent: (
        <>
          <VDiv className={"row"}>
            {productRequestList.length > 0 && !showNoRequestsFound
              ? productRequestList.map((item) => {
                  return (
                    <VDiv className="col-md-4 mb-3" key={item?.id}>
                      <ServiceRequestCard
                        item={item}
                        onClick={() => goToProductDetail(item)}
                        showStatus={false}
                      />
                    </VDiv>
                  );
                })
              : showNoRequestsFound && (
                  <div>
                    <p>No Requests Found</p>
                  </div>
                )}
          </VDiv>
        </>
      ),
    },
  ];
  const [visibleTab, setVisibleTab] = useState(data[0].id);

  const listTitles = data.map((item) => (
    <li
      onClick={() => setVisibleTab(item.id)}
      className={
        visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"
      }
    >
      {item.tabTitle}
    </li>
  ));
  const listContent = data.map((item) => (
    <p style={visibleTab === item.id ? {} : { display: "none" }}>
      {item.tabContent}
    </p>
  ));

  return (
    <VDiv>
      {/* <SectionHeader title="Service Requests" /> */}
      <HSpacer size="sm" />
      <VDiv className={"row"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            fetchServicesByNumber(tenantId, locationId, phoneNumber);
            productRequestsByNumber(tenantId, locationId, phoneNumber);
            setShowRequests(true);
            // setShow(true);
            // fetStatuses();
          }}
          type="submit"
        >
          <Input
            className={"my-2"}
            placeHolder={"Mobile Number"}
            enabled={true}
            type={"text"}
            name={"phoneNumber"}
            onInput={(e) => {
              e.target.value = e.target.value.slice(0, 10).replace(/\D/g, "");
            }}
            value={phoneNumber}
            onChange={(e) => {
              handlePhoneNumberChange(e);
              
            }}
            required
          />
          <button
            type="submit"
            className="mb-3 col-sm-6 btn btn-primary w-fit image-pop-zoom-up"
          >
            Find Request History
          </button>
        </form>
        {/* <Statuses list={statuses} onClick={onFilterClick} /> */}
        {showRequests && (
          <div className="tabs">
            <ul className="tabs-titles">{listTitles}</ul>
            <div className="tab-content">{listContent}</div>
          </div>
        )}
        
      </VDiv>

      {showForm && (
        <ServiceRequestPopup
          title={formTitle}
          // services={services}
          formData={selectedService}
          onCancel={closeRequestForm}
          onSave={handleSave}
        />
      )}
    </VDiv>
  );
}
