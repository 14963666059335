import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import VDiv from "../containers/vdiv";

import { getServiceRequestDetail } from "../../service/ServiceRequestService";

import PageTitle from "../elements/texts/page-title";
import { getFormattedDateAndTime } from "../../utils/formatters";
import HDiv from "../containers/hdiv";

export default function ServiceRequestDetailSection() {
  const { tenantId, roomId, id } = useParams();

  const [serviceRequest, setServiceRequest] = useState(undefined);

  const fetchServiceRequest = async () => {
    const response = await getServiceRequestDetail(tenantId, roomId, id);
    setServiceRequest(response);
  };

  useEffect(() => {
    fetchServiceRequest();
    // eslint-disable-next-line
  }, []);

  return (
    <VDiv>
      {serviceRequest && (
        <VDiv className={"row"}>
          <PageTitle text={serviceRequest.service.name} />
          <p>{serviceRequest.comment} </p>
          <div className="timeline timeline-one-side mt-3">
            {serviceRequest.histories.map((item) => (
              <StatusBlock key={item.id} item={item} />
            ))}
          </div>
        </VDiv>
      )}
    </VDiv>
  );
}

function StatusBlock({ item }) {
  const [openFile, setOpenFile] = useState(false);

  const [selectedFile, setSelectedFile] = useState();

  const onFileClick = (file) => {
    setSelectedFile(file);
    setOpenFile(true);
  };

  const closeFile = (event) => {
    event.stopPropagation();
    setOpenFile(false);
  };

  return (
    <div className="timeline-block mb-3">
      <span className="timeline-step">
        <img src="/assets/images/icons/completed-icon.png" alt="Done" />
      </span>
      <div className="timeline-content">
        <h6 className="text-dark text-sm font-weight-bold mb-0">
          {item.status.displayName}
        </h6>
        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
          {getFormattedDateAndTime(new Date(item.createdOn))}
        </p>
        {item.comment && <p className="comment">{item.comment}</p>}
        <HDiv className="mt-3 mb-3">
          {item.attachments.map((attachment) => (
            <VDiv
              onClick={() => onFileClick(attachment)}
              className="file-preview"
              key={attachment.id}
              style={{
                backgroundImage: `url(${attachment.attachment.mediaUrl})`,
              }}
            ></VDiv>
          ))}
        </HDiv>
      </div>

      {openFile ? (
        <VDiv className="show-file-container">
          <img
            className="file"
            alt={selectedFile.attachment.name}
            src={selectedFile.attachment.mediaUrl}
          />
          <img
            className="close-btn"
            src="/assets/images/icons/icon-close-white.png"
            alt="close"
            onClick={(event) => closeFile(event)}
          />
        </VDiv>
      ) : null}
    </div>
  );
}
