import React from "react";

export default function SearchBox({ placeholder, handleSearchChange }) {
  return (
    <div className="search-box flex">
      <input
        className="form-control fw-medium text-dark text-input search-input"
        onChange={handleSearchChange}
        placeholder={placeholder ? placeholder : "Search..."}
      />
      <img
        className="search-icon"
        src="/assets/images/icons/search-icon.png"
        alt="Search"
      />
    </div>
  );
}
