import { 
    getServiceRequestApiUrl,
    getSaveServiceRequestApiUrl,
    getServiceRequestDetailApiUrl,
    getServiceRequestStatusesApiUrl,
    getServiceRequestByStatusApiUrl
 } from "./ApiUrls";
import { get, post } from "./Service";

export const getServiceRequests = async (tenantId, roomId) => {
    const response = await get(getServiceRequestApiUrl(tenantId, roomId));
    if(!response) {
        return []
    }
    return response;
};

export const getServiceRequestByStatus = async (tenantId, roomId, id) => {
    const response = await get(getServiceRequestByStatusApiUrl(tenantId, roomId, id));
    if(!response) {
        return []
    }
    return response;
};


export const saveServiceRequest = async (tenantId, roomId, data) => {
    const response = await post(getSaveServiceRequestApiUrl(tenantId, roomId), data);
    return response;
};

export const getServiceRequestDetail = async (tenantId, roomId, id) => {
    const response = await get(getServiceRequestDetailApiUrl(tenantId, roomId, id));
    return response;
};

export const getServiceRequestStatuses = async (tenantId, locationId) => {
    const response = await get(getServiceRequestStatusesApiUrl(tenantId, locationId));
    return response;
};




