import React from "react";

export default function Input({
  enabled,
  name,
  type,
  value,
  onChange,
  placeHolder,
  required,
  label,
  className,
  onInput
}) {
  return (
    <div className={`edc-input-group ${className}`}>
      <label className="form-label">
        {label ? label : placeHolder}{" "}
        {required && <span className="text-danger"> *</span>}
      </label>
      <input
        className="form-control text-input"
        disabled={!enabled}
        name={name}
        required={required}
        onChange={(event) => onChange(event)}
        type={type}
        value={value}
        placeholder={placeHolder}
        onInput={onInput}
      />
    </div>
  );
}
