import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ServiceCard from "../cards/service-card";
import VDiv from "../containers/vdiv";
import SectionHeader from "../elements/section-header";

import {
  getServicesByCategory,
  getServices,
} from "../../service/DashboardService";
import ServiceRequestPopup from "../popups/service-request-popup";

export default function Services({ roomName, category, goToDetail = false }) {
  const { tenantId } = useParams();

  const [list, setList] = useState([]);

  const [showForm, setShowForm] = useState(false);

  const [selectedService, setSelectedService] = useState();

  const formTitle = "Create Service Request";

  const fetchServices = async () => {
    let response;
    if (category === "all") {
      response = await getServices(
        tenantId,
        localStorage.getItem("locationId")
      );
    } else {
      response = await getServicesByCategory(
        tenantId,
        localStorage.getItem("locationId"),
        category
      );
    }
    setList(response?.filter((item) => item.deleted === false));
  };

  const openRequestForm = (item) => {
    setSelectedService({ service: item });
    setShowForm(true);
  };

  const closeRequestForm = (item) => {
    setShowForm(false);
  };

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line
  }, [category]);
  return (
    <VDiv>
      <SectionHeader title="Services" />
      <VDiv className={"row"}>
        {list.length > 0 ? (
          // eslint-disable-next-line
          list?.map((item, index) => {
            if (!item.deleted) {
              return (
                <VDiv className="col-md-4 mb-3" key={index}>
                  <ServiceCard
                    title={item.title}
                    description={item.name}
                    date={item.date}
                    time={item.time}
                    onClick={() => openRequestForm(item)}
                    background={item.background}
                    showStatus={false}
                  />
                </VDiv>
              );
            }
          })
        ) : (
          <p>Currently , No services in this Category!!</p>
        )}
      </VDiv>
      {showForm && (
        <ServiceRequestPopup
          goToDetail={goToDetail}
          title={formTitle}
          services={list}
          formData={selectedService}
          onCancel={closeRequestForm}
          roomName={roomName}
          showNextButton={false}
        />
      )}
    </VDiv>
  );
}
