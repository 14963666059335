
import VDiv from "../components/containers/vdiv";
import Page from "../components/general/page";
import TabBar from "../components/layout/tab-bar"

export default function Chat() {  
    return (
      <Page showHeader title={"Chat"} >
        <VDiv>

        </VDiv>
        <TabBar selectedIndex={4}/>
      </Page>
    );
  }