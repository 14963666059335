import React from "react";
import VDiv from "../containers/vdiv";
import HDiv from "../containers/hdiv";
import Paragraph from "../elements/texts/paragraph";
import RightArrow from "../elements/right-arrow";

export default function ServiceCard({
  title,
  description,
  onClick,
  className,
}) {
  return (
    <HDiv
      className={`assignment-card v-align-center cursor-pointer image-pop-zoom-up ${className ? className : ""
        }`}
      onClick={onClick}
    >
      <VDiv className="grow">
        <Paragraph text={description} />
      </VDiv>
      <RightArrow />
    </HDiv>
  );
}
