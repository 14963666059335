import axios from "axios";

export async function Initialize() {

  axios.interceptors.request.use((req) => {
    const token = localStorage.getItem("token");

    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }

    return req;

  });

  axios.interceptors.response.use((response) => response, (error) => {
    console.log(error);
    /*if (error.response.status === 401) {
      localStorage.clear();
      if (error.request.responseURL.indexOf("/oauth/token") < 0) {
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
    }*/

    return error;
  });
}
