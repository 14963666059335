import FormPopup from "./form-popup";
import TextArea from "../inputs/textarea";
import Input from "../inputs/input";
import useProductRequestPopup from "../../hooks/useProductRequestPopup";

export default function ProductRequestPopup({
  title,
  onCancel,
  formData,
  showNextButton,
  onNextClick,
}) {
  const { data, handleInputChange } = useProductRequestPopup(formData);

  return (
    <FormPopup
      title={title}
      onCancel={onCancel}
      showNextButton={showNextButton}
      onNextClick={onNextClick}
      data={data}
    >
      <Input
        className={"my-2 text-black"}
        placeHolder={"Mobile Number "}
        enabled={true}
        type={"number"}
        name={"phoneNumber"}
        value={data.phoneNumber}
        onChange={handleInputChange}
        required
      />
      <Input
        className={"my-2 text-black"}
        placeHolder={"Enter name"}
        label={"Enter your Name"}
        enabled={true}
        type={"text"}
        name={"userName"}
        value={data.userName}
        onChange={handleInputChange}
      />
      <Input
        className={"my-2 text-black"}
        placeHolder={"Enter your Email"}
        enabled={true}
        type={"email"}
        name={"email"}
        value={data.email}
        onChange={handleInputChange}
      />
      <TextArea
        value={data.comment}
        className="mb-3 col-md-12 text-black"
        onChange={handleInputChange}
        label="Notes/Comments"
        name="comment"
        placeHolder="Enter your notes/comments"
      />
    </FormPopup>
  );
}
