import React from "react";
import Page from "../components/general/page";
import VDiv from "../components/containers/vdiv";
import ProductRequestDetailSection from "../components/sections/product-request-detail-section";

const ProductRequestDetail = () => {
  return <Page showHeader title={"Product request detail"} showBackNavigation={true} >
  <VDiv>
    <ProductRequestDetailSection />
  </VDiv>
</Page>
};

export default ProductRequestDetail;
