import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FormPopup from "./form-popup";
import TextArea from "../inputs/textarea";
import { getUploadApiUrl } from "../../service/ApiUrls";
import { upload } from "../../service/Service";
import FileSelector from "../inputs/file-selector";
import ProcessLoader from "../general/process-loader";
import SelectGroup from "../inputs/select-group";
import { saveServiceRequest } from "../../service/ServiceRequestService";
import { getHistoryUrl } from "../../Constant";
import Input from "../inputs/input";

export default function ServiceRequestPopup({
  title,
  onSave,
  onCancel,
  formData,
  services,
  goToDetail = false,
  showNextButton,
  onNextClick,
  
}) {
  const { tenantId, roomId } = useParams();

  const [data, setData] = useState({ ...formData });

  const [files, setFiles] = useState([]);

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const [apiInProgress, setApiInProgress] = useState(false);


  const navigate = useNavigate();

  const handleInputChange = (event) => {
    event.stopPropagation();
    if (event.target.name === "service") {
      setData((state) => ({
        ...state,
        service: { id: event.target.value },
      }));
      return;
    }
    setData((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const onFileSelect = (files) => {
    setFiles(files);
  };

  const handleSubmit = async () => {
    setApiInProgress(true);
    let attachments = [];
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let attachment = await uploadFile(files[i]);
        attachments.push({ attachment });
      }
    }
    let histories = [];
    histories.push({ attachments, comment: data.comment });
    data.room = { id: roomId };
    data.locationId = localStorage.getItem("locationId");
    data.tenantId = tenantId;
    data.histories = histories;
    const response = await saveServiceRequest(tenantId, roomId, data);
    setApiInProgress(false);
    if (goToDetail) {
      navigate(getHistoryUrl(tenantId, roomId));
    } else if (onSave) {
      onSave(response);
    }
  };

  const uploadFile = async (file) => {
    setUploadInProgress(true);
    const data = {
      tenantId,
      locationId: localStorage.getItem("locationId"),
      folderName: roomId,
      roomId,
      userId: localStorage.getItem("userId"),
      file: file.file,
    };
    let response = await upload(getUploadApiUrl(tenantId), data);
    setUploadInProgress(false);
    if (response) {
      return response;
    }
  };

  

  return (
    <FormPopup title={title} onSave={handleSubmit} onCancel={onCancel} showNextButton={showNextButton} onNextClick={onNextClick} data={data}>
      <SelectGroup
        className="mb-1 col-md-12"
        value={data?.service?.id}
        name="service"
        label="Select Service"
        list={services}
        valueProperty="id"
        onChange={handleInputChange}
      />
      <Input
        className={"my-2 text-black"}
        placeHolder={"Mobile Number "}
        enabled={true}
        type={"number"}
        name={"phoneNumber"}
        value={data.phoneNumber}
        onChange={handleInputChange}
        required
      />
      <Input
        className={"my-2 text-black"}
        placeHolder={"Enter name"}
        label={"Enter your Name"}
        enabled={true}
        type={"text"}
        name={"userName"}
        value={data.userName}
        onChange={handleInputChange}
      />
      <Input
        className={"my-2 text-black"}
        placeHolder={"Enter your Email"}
        enabled={true}
        type={"email"}
        name={"email"}
        value={data.email}
        onChange={handleInputChange}
      />
      <TextArea
        value={data.comment}
        className="mb-3 col-md-12 text-black"
        onChange={handleInputChange}
        label="Notes/Comments"
        name="comment"
        placeHolder="Enter your notes/comments"
      />
      <FileSelector
        onFileSelect={onFileSelect}
        label="Upload Photos"
        showProgress={uploadInProgress}
        multiple
      />
      {apiInProgress && <ProcessLoader />}
    </FormPopup>
  );
}
