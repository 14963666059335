import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import VDiv from "../containers/vdiv";
import SectionHeader from "../elements/section-header";
import {
  getProducts,
  getCategories,
  getProductsByCategory,
} from "../../service/ProductService";
import HSpacer from "../elements/h-spacer";
import ProductCard from "../cards/product-card";
import Categories from "./categories";
import useCart from "../../hooks/useCart";

export default function ProductsSection({ search,setOrderPlaced }) {
  const { tenantId, roomId } = useParams();

 const {onAddToCart} = useCart()  
  const [list, setList] = useState([]);

  const [categories, setCategories] = useState([]);
  
  const filteredProducts = list && list?.filter((item) =>
    item?.name?.toLowerCase().includes(search.toLowerCase())
  );
  

  
  
  const fetchProducts = async (category) => {
    let response = [];
    if (category.id === "all") {
      response = await getProducts(
        tenantId,
        localStorage.getItem("locationId")
      );
    } else {
      response = await getProductsByCategory(
        tenantId,
        localStorage.getItem("locationId"),
        category.id
      );
    }
    setList(response);
  };

  const goToDetail = (item) => {};

  const fetchCategories = async () => {
    const response = await getCategories(
      tenantId,
      localStorage.getItem("locationId"),
      "PRODUCT"
    );
    setCategories(response);
  };

 
  useEffect(() => {
    fetchProducts({ id: "all" });
    fetchCategories();
    //eslint-disable-next-line
  }, [roomId]);
  return (
    <VDiv>
      <SectionHeader title="Products" />
      <Categories list={categories} onClick={fetchProducts} />
      <HSpacer size="sm" />

      <VDiv className={"row"}>
        {filteredProducts.length > 0 ? (
          filteredProducts.map((item) => {
            return (
              <VDiv className="col-md-4 mb-3" key={item.id}>
                <ProductCard
                  item={item}
                  onClick={() => goToDetail(item)}
                  onAddToCart={(event) => onAddToCart(event, item)}
                  showStatus={false}
                  setOrderPlaced={setOrderPlaced}
                />
              </VDiv>
            );
          })
        ) : (
          <div>
            <p>No Products Found</p>
          </div>
        )}
      </VDiv>
    </VDiv>
  );
}
