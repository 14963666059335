import React from 'react'
import VDiv from '../containers/vdiv'
import HDiv from '../containers/hdiv'
import HGrow from '../elements/h-grow'
import Price from '../general/price'

export default function ProductCard({item, onClick, onAddToCart, className}) {
    return(
        <>
        <VDiv className={`product-card ${className ? className : ''}`} onClick={onClick}>
            <HDiv>
                <VDiv className="detail">
                    <h6>{item.name}</h6>
                    <p className='desc mb-2'>{item.description}</p>
                    <Price value={item.price} />
                {item.stockableSr === "Y" && item.productQuantity > 0 &&   <p className='text sm text-danger'>Stock:{item.productQuantity}</p>}
                </VDiv>
                <HGrow/>
                <div className='product-cover-image' style={{backgroundImage: `URL(${item.coverImage})`}}>
                    <button type='button' className='add-to-cart' onClick={onAddToCart} >ADD TO CART</button>
                </div>
            </HDiv>

        </VDiv>
        </>
    )
}