const HOST = "https://dev-api-concierge.gjglobalsoft.com";

export const BASE_AUTH_URL = `${HOST}/`;

const BASE_ATTACHMENT_URL = `${HOST}/v1/user/attachment`;

const BASE_CONCIERGE_URL = `${HOST}/v1/user/tenant/`;

export const LOGIN = `${BASE_AUTH_URL}oauth/token`;

export const UPLOAD_URL = `${BASE_ATTACHMENT_URL}`;

export const getUploadApiUrl = (tenantId) => {
  return BASE_ATTACHMENT_URL;
};

export const getCategoriesApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category`;
};

export const getServicesByCategoryApiUrl = (
  tenantId,
  locationId,
  categoryId
) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category/${categoryId}/services`;
};

export const getCategoryApiUrl = (tenantId, locationId, categoryId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category/${categoryId}`;
};

export const getServicesApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/services`;
};

export const getRoomServicesApiUrl = (tenantId, roomId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/room/${roomId}/room-service`;
};

export const getUserNumberServicesApiUrl = (tenantId, number) => {
  return `${BASE_CONCIERGE_URL}user/${tenantId}/service-request-history/by-phone/${number}`;
};

export const getSaveServiceRequestApiUrl = (tenantId, roomId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/room/${roomId}/service-request`;
};

export const getServiceRequestApiUrl = (tenantId, roomId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/room/${roomId}/service-request`;
};

export const getServiceRequestByStatusApiUrl = (tenantId, roomId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/room/${roomId}/service-request/status/${id}`;
};

export const getServiceRequestDetailApiUrl = (tenantId, roomId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/room/${roomId}/service-request/${id}`;
};
export const getProductRequestDetailApiUrl = (tenantId, roomId, id) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/room/${roomId}/product-request/${id}`;
};

export const getRoomDetailApiUrl = (tenantId, roomId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/room/${roomId}`;
};

export const getServiceRequestStatusesApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/service-request-status`;
};

export const getProductsApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/product`;
};

export const getProductsByCategoryApiUrl = (
  tenantId,
  locationId,
  categoryId
) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/product/category/${categoryId}`;
};

export const getCategoriesByTypeApiUrl = (tenantId, locationId, type) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/category/type/${type}`;
};

export const getCartApiUrl = (tenantId, locationId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/cart`;
};

export const getAddToCartApiUrl = (tenantId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/cart-item`;
};



// export const getDeleteCartItemApiUrl = (tenantId) => {
//   return `${BASE_CONCIERGE_URL}${tenantId}/cart-item`;
// };
export const getDeleteCartItemApiUrl = (tenantId,cartItemId) => {
  return `${BASE_CONCIERGE_URL}${tenantId}/cart-item/${cartItemId}`;
};
export const NOTIFY_TOKEN_URL = `${BASE_AUTH_URL}v1/notification-tokens`;


export const sendProductRequest = (tenantId,roomId) =>{
  return `${BASE_CONCIERGE_URL}${tenantId}/room/${roomId}/product-request`;
}

export const productRequestsByNumber = (tenantId,locationId,phoneNumber) =>{
   return `${BASE_CONCIERGE_URL}${tenantId}/location/${locationId}/product-request-history/by-phone/${phoneNumber}`
}