  import React from "react";

const ModalPopup = ({ children, id ,title,className}) => {

  return (
    <div className="modal fade" id="popup" tabIndex="-1" aria-hidden="true"  >
       <div className="modal-dialog d-flex me-0 mt-0 mb-0 h-100 modal-icongroup" >
           <div className="modal-content">
           <div className="modal-header p-3">
               <h1 className="modal-title fs-6 text-dark fw-semibold " >{title}</h1>
               <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" ></button>
           </div>
           <div className="modal-body overflow-auto">
               {children}
           </div>
           </div>
       </div>
   </div>
  );
};

export default ModalPopup;
