import { 
    getProductsApiUrl, getCategoriesByTypeApiUrl, getProductsByCategoryApiUrl, productRequestsByNumber, getProductRequestDetailApiUrl
 } from "./ApiUrls";
import { get} from "./Service";


export const getProducts = async (tenantId, locationId) => {
    const response = await get(getProductsApiUrl(tenantId, locationId));
    if(!response) {
        return []
    }
    return response;
};

export const getProductsByCategory = async (tenantId, locationId, categoryId) => {
    const response = await get(getProductsByCategoryApiUrl(tenantId, locationId, categoryId));
    if(!response) {
        return []
    }
    return response;
};



export const getCategories = async (tenantId, locationId, type) => {
    const response = await get(getCategoriesByTypeApiUrl(tenantId, locationId, type));
    if(!response) {
        return []
    }
    return response;
};

export const getProductRequestByPhoneNumber = async(tenantId, locationId,phoneNumber) =>{
   const response = await get(productRequestsByNumber(tenantId, locationId,phoneNumber))
   if(!response){
    return []
   }
   return response;
}


export const getProductRequestDetail = async (tenantId, roomId, id) => {
    const response = await get(getProductRequestDetailApiUrl(tenantId, roomId, id));
    return response;
};

