import React from "react";
// import { useParams } from "react-router-dom";
import VDiv from "../containers/vdiv";
import BgOverlay from "../elements/bg-overlay";
import HDiv from "../containers/hdiv";
import Text from "../elements/texts/text";
import HGrow from "../elements/h-grow";

// import { getCart } from "../../service/CartService";
import ModalPopup from "../modal-popup";
import CartItem from "../cart-item";
import useCart from "../../hooks/useCart";
import ProductRequestPopup from "../popups/product-request-popup";

export default function ProductsHeader({ title, subTitle }) {

  const formTitle = "Create Product Request";

  const { cartItems, addQuantity, reduceQuantity, deleteItem, onProceedToOrder, orderPlaced,cartCount,cartDetails,updateCartQuantity,openFormPopup,closeFormPopup,showForm,openCartPopup,productFormData } = useCart();

  return (
    <VDiv className="page-header">
      <BgOverlay />
      <HDiv className="brand">
        <img
          className="logo"
          src="../assets/images/conceirge-logo.svg"
          alt="GJ Concierge"
        />
        <VDiv className="welcome-msg grow">
          <Text
            text={title ? title : "Hello User!"}
            size="mg"
            className="user-info white"
          />
          <Text
            text={subTitle ? subTitle : "Welcome to GJ Concierge"}
            size="sm"
            className="brand-info white"
          />
        </VDiv>
        <HGrow />
        <VDiv className="menus">
          <span className="menu-item cart-icon cursor-pointer" id="cart-icon" 
          onClick={openFormPopup}
          >
            <i className="bx bx-cart bx-sm" />
            <span
              className="count justify-content-center align-items-center p-2"
              id="cart-count"
            >
              {cartCount}
            </span>
          </span>
          <span className="menu-item">
            <img
              className="icon"
              alt="User"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAC20lEQVRoQ+1Yi1ECMRDlKtAOxAqUCsQK1ArUCoQOsAK1ArUCpQKxArUCoQOtAN9zcjNrzHcTB25kZ26AI5t9L7ubbLbpdVyajuPvbQis2oPVPbBcLk9Baohn3zzk+Gqex6ZppjVJVyMA4McAdoWnHwE4x/9jEHmsQaQKAYAn8FEmoGuQGGfq/BpeTADgrzHrhRLIDUjkEv9hqoiACZsHB/h7vOMKM/Z7GMd8IFDmhy0nJeFUSuDdivlP/B62wG2khsgM77fEf3OM31V6UH8OAMwZjN5ahgc+8O04Q+LF0lN7Qe0BALmzQiI5nh269yDOBcmWEgJcRcZ2K9HVD3jhFQQG2eihUEJgKQ0CQNZc8EKRfms7y6gEXAqgVL8GAW6Re5VC6A0OlOGYHE0lHuh8End7G6WPEcdzfOwIf3/g+2HkIHvCmG2hs8D4fnLMWAPVIWQIsAJ1lRIML54LspRgvUSv2aI+xDhREQFDorvFXLuUyoo0+eQOhVexBwQJhhO9IXPCZXuBl6OSClROWo2ARYRkuK+358QbvjMfeKWschNr7VUnoN1NtHr/m4Cp7XnLYrhwb08tBxhOPDNmeKaxO0T1JDatkwkm7mtdb+nN8VvVqcgKIQAnYN7ChpWA29PQI+fwCAklSTIBgCdonrqyDEgykjkoWI7YcyURMOBZw7iEHQiuHG9V36VDTEzuMF+43R55xifd8KIETNjw+mivPFuEPJCS3e0CaubnAWgToSdIIjh/CgGuPMNHChOORqsJiLBvxA6flBnsHIaMBAl4WifVwbcAPSSC1WqMgN244p7NuP0zAQmWGjKcgo0vLwFPA2o3FpOlzExOcOGkeBM6RMCu89XNp1xSDi9cYuEmrnlCBGZQOBBKPGDucsFoxjty7xm2h7kE1J03DWip4whfb+cu5IEqnTMtmdTG14aAdoVjesUeiBlYl/+jpcS6APXh2BBYtYc2Hli1B74AqTg2QBc21X0AAAAASUVORK5CYII="
            />
          </span>
        </VDiv>
      </HDiv>
      <ModalPopup title={"Cart"} >
        <CartItem cartItems={cartItems} addQuantity={addQuantity} reduceQuantity={reduceQuantity} deleteItem={deleteItem} onProceedToOrder={onProceedToOrder} orderPlaced={orderPlaced} cartDetails={cartDetails} updateCartQuantity={updateCartQuantity} openFormPopup={openFormPopup} />
      </ModalPopup>
      {showForm &&
       <ProductRequestPopup
          title={formTitle}
          formData={productFormData}
          onCancel={closeFormPopup}
          onNextClick={openCartPopup}
          showNextButton={true}
          showDropdown={false}
          productFormData={productFormData}
        />
        }
    </VDiv>
  );
}
