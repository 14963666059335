/* eslint-disable array-callback-return */
import React from "react";
import { priceFormatter } from "../utils/formatters";

function CartItem(props) {

    const cartDataString = sessionStorage.getItem('cartData');
  let cartData;
    if (cartDataString !== "undefined") {
       cartData = JSON.parse(cartDataString);
    } else {
      console.error('No cart data found in sessionStorage');
    }  
   

    
    return (
        <>
            {/* {props?.cartItems?.length > 0 && !props?.orderPlaced ? ( */}
            {cartData?.length > 0 && cartData ? (
                <>
                    {cartData?.map((item, index) => {                          
                        if(item.requested === false){
                            return (
                                
                                <div className="image-dropdown-wrapper py-3" key={index} style={{ color: "grey" }}>
                                    <div className="cart-product-img d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center w-100">
                                            <div className="bg-light rounded">
                                                <img src={item?.product?.coverImage} height={80} width={80} className={"object-fit-contain p-2"} alt="img" />
                                            </div>
                                            <div className="d-block w-100 ms-4">
                                                <div>
                                                    <h6 className="fw-semibold ellipsis-text">{item?.product?.name}</h6>
                                                    <p className="d-flex justify-content-between m-0">
                                                        <h6 className="fw-medium">{priceFormatter(item?.product?.price)}</h6>
                                                    </p>
                                                    {item.product.productQuantity > 0 && <p className="text sm text-danger">Stock : {item.product.productQuantity}</p>}
                                                </div>
                                                <div className="">
                                                    <i className="bx bx-minus bx-sm bg-light rounded-5" onClick={() => props?.updateCartQuantity(item,"REDUCE",item.product.productQuantity,index)} style={{ color: "grey", width: "24px", height: "24px" }} />                                               {/* <img src="/assets/images/minus.svg" alt="minus" width={24} height={24} /> */}
                                                    <span className="p-3 ">{item?.quantity}</span>
                                                    <i className="bx bx-plus bx-sm bg-light rounded-5" onClick={() => props?.updateCartQuantity(item,"ADD",item.product.productQuantity,index)} style={{ color: "grey", width: "24px", height: "24px" }} />
                                                    <i className="bx bx-trash bx-sm  rounded-5 p-0" style={{ color: "red", float: "right" }} onClick={() => props?.deleteItem(item.id)} />
                                                    {/* <img src="/assets/images/minus.svg" alt="minus" width={24} height={24} /> */}
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );   
                        }
                                                                      
                        
                    })}
                    <div className="py-2" style={{ color: "grey" }}>
                        <span className="d-flex justify-space-between justify-content-between fw-medium">
                            {/* <p>Total Amount</p>
                            <p> {priceFormatter(props?.cartDetails?.toalAmount)}</p> */}
                        </span>
                        {/* <p className="m-0">Inclusive of all taxes</p> */}
                        {/* <span className="d-flex justify-space-between justify-content-between fw-medium">
                            <p>Sub-Total</p>
                            <p> {priceFormatter(totalAmount)}</p>
                        </span> */}
                        <span className="d-flex justify-space-between justify-content-between fw-bold ">
                            <p>Total</p>
                            <p>{priceFormatter(props?.cartDetails?.toalAmount)}</p>
                        </span>
                    </div>
                    <div className="d-flex gap-2">
                    <button className="w-100 fw-medium btn btn-outline-secondary"   onClick={()=>{
                        props?.openFormPopup()
                    }} data-bs-dismiss="modal">Back</button>
                    <button type="button" className="btn btn-primary w-100 button-group border-0 fw-medium" onClick={() => { props?.onProceedToOrder() }}>Proceed to Checkout</button>
                    </div>
                </>
            ) : props?.orderPlaced ?
                (
                    <div className="align-items-center justify-content-center d-flex flex-column pt-5" style={{ color: "grey" }}>
                        <p className="text-dark" >Thanks for the order</p>
                        <p className="text-dark" >Your order has been placed</p>

                        <div className="align-items-center py-2 w-100 justify-content-center d-flex">

                            <img src="/assets/images/order-placed.gif" alt="img" />
                        </div></div>
                ) :
                (
                    <div className="align-items-center py-5 ">
                        <div className="d-flex justify-content-center text-primary py-5 fw-semibold">Your cart is empty</div>
                            <button type="button" data-bs-dismiss="modal" className="btn btn-primary w-100 custom-button fw-medium my-5">Browse Product</button>
                    </div>
                )}
        </>
    );
}

export default CartItem;
