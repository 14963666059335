import React from "react";

export const message = (msg,status) => {
    if(status === "success"){
        document.querySelector(".toast-success")?.classList.add("d-block")
if(document.getElementById("toast-success-message")){

    document.getElementById("toast-success-message").innerHTML = msg;
}
        setTimeout(() => {
            document.querySelector(".toast-success")?.classList?.remove("d-block")
        }, 3000)
    }
    else if(status === "failure"){
        document.querySelector(".toast-failure")?.classList.add("d-block")
        if(document.getElementById("toast-failure-message")){

            document.getElementById("toast-failure-message").innerHTML = msg;
        }
        setTimeout(() => {
            document.querySelector(".toast-failure").classList.remove("d-block")
        }, 4000)
    }
    
}

const ToastMessage = ({ show, onClose }) => {
    return (
        <>
        <div className="w-100 d-flex justify-content-center" >
            <div className="toast toast-success  w-auto fade-scale-box position-absolute mt-2 " role="alert" aria-live="assertive" aria-atomic="true" style={{zIndex:3050}}>
                <div className="toast-header rounded-3 ">
                    <div className="d-flex gap-2 align-items-center"><div className="wrapper"> 

                    <img src="/assets/images/order-placed.gif" alt="" height="25px" width="25px"/>

                    </div>
                        <strong className="me-auto text-dark" id="toast-success-message">Bootstrap</strong></div>
                </div>

            </div>


            <div className="toast toast-failure w-auto fade-scale-box position-absolute mt-2 " role="alert" aria-live="assertive" aria-atomic="true" style={{zIndex:3050}}>
                <div className="toast-header rounded-3 ">
                    <div className="d-flex gap-2 align-items-center"><div className="wrapper"> 
                    <img src="/assets/images/order-placed.gif" alt="" height="25px" width="25px"/>

                    </div>
                        <strong className="me-auto text-dark" id="toast-failure-message">Bootstrap</strong></div>
                </div>

            </div>
        </div>
       
            
       
        </>
        
    );
};

export default ToastMessage;