import React from "react";

export default function Toast({ ToastMsg, ToastBody }) {
  return (
    <div className="container position-relative">
      <div
        className="toast m-3 rounded-3 position-absolute bg-light z-index-1212 top-0 end-0 show"
        id="myToast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="d-flex flex-column rounded-1 justify-content-between p-3 ">
          <div className="toast-body text-success text-uppercase p-0 fw-bolder">
            {ToastMsg + " Request"}
          </div>
          <div className="toast-body p-0 py-1">{ToastBody}</div>
        </div>
      </div>
    </div>
  );
}

export const ToastMessage = () => {};
