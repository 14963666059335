import React from 'react'
import VDiv from '../containers/vdiv'
import HDiv from '../containers/hdiv'
import Time from '../elements/texts/time'
import RightArrow from '../elements/right-arrow'
import { getFormattedDateAndTime } from "../../utils/formatters";
import PageSubTitle from '../elements/texts/page-sub-title'
import HGrow from '../elements/h-grow'


export default function ServiceRequestCard({ item, onClick, className }) {
    return (
        <HDiv className={`assignment-card v-align-center image-pop-zoom-up ${className ? className : ''}`} onClick={onClick}>
            <VDiv className="grow">
                <HDiv className="v-align-center">
                    <PageSubTitle text={item?.product?.name ?item?.product?.name :item?.service?.name} />

                </HDiv>
                <Time text={getFormattedDateAndTime(new Date(item?.createdOn))} />
            </VDiv>
            <HGrow />
            <span className={`status status-${item?.status?.name} fw-medium`}>{item?.status?.displayName}</span>
            <RightArrow />
        </HDiv>
    )
}