
import VDiv from "../components/containers/vdiv";
import Page from "../components/general/page";
import TabBar from "../components/layout/tab-bar"

export default function More() {  
    return (
      <Page showHeader title={"Settings"} >
        <VDiv>

        </VDiv>
        <TabBar selectedIndex={5}/>
      </Page>
    );
  }