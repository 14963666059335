import moment from "moment";

const dateTimeFormateFull = "DD/MMM/YYYY hh:mm a"

export const getFormattedDateAndTime = (date) => {
    return moment(date).format(dateTimeFormateFull)
}

export const priceFormatter = (num) => {
    let curr = 0.0;
    if (num) {
      curr = num.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR" ,
      });
    }
    return curr;
  }