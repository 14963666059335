import { 
    getCartApiUrl,
    getAddToCartApiUrl,
    getDeleteCartItemApiUrl,
    sendProductRequest
 } from "./ApiUrls";
import { post, put, Delete} from "./Service";


export const getCart = async (tenantId, locationId, data) => {
    const response = await post(getCartApiUrl(tenantId, locationId), data);
    return response;
};

export const addToCart = async (tenantId, data) => {
    const response = await post(getAddToCartApiUrl(tenantId), data);
    return response;
};

export const updateCartItem = async (tenantId, data) => {
    const response = await put(getAddToCartApiUrl(tenantId), data);
    return response;
};

// export const deleteCartItem = async (tenantId, data) => {
//     const response = await Delete(getDeleteCartItemApiUrl(tenantId), data);
//     return response;
// };
export const deleteCartItem = async (tenantId,cartItemId) => {
    const response = await Delete(getDeleteCartItemApiUrl(tenantId,cartItemId));
    return response;
};

export const postProductRequest = async (tenantId, roomId,data) => {
    const response = await post(sendProductRequest(tenantId,roomId), data);
    return response;
};