// import SearchBox from "../components/layout/search-box";
import HSpacer from "../components/elements/h-spacer";
import VDiv from "../components/containers/vdiv";
import Page from "../components/general/page";
import TabBar from "../components/layout/tab-bar";
import ServiceRequestHistory from "../components/sections/service-request-history";
import { useState } from "react";
// import Input from "../components/inputs/input";

export default function ServiceHistory() {
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  return (
    <Page showHeader title={"Request history"}>
      <VDiv>
        {/* <SearchBox
          handleSearchChange={handleSearchChange}
          placeholder={"Search services..."}
        /> */}
        {handleSearchChange}

        <HSpacer size="lg" />
        <ServiceRequestHistory search={search} />
      </VDiv>
      <TabBar selectedIndex={2} />
    </Page>
  );
}
