import React from "react";

export default function SelectGroup({
  className,
  enabled = true,
  label,
  name,
  value,
  type,
  onChange,
  placeHolder,
  list,
  valueProperty,
}) {
  return (
    <div className={className + " edc-input-group"}>
      <label className="form-label">{label}</label>
      <select
        className="form-control text-input"
        disabled={!enabled}
        name={name}
        onChange={(event) => onChange(event)}
        type={type}
        value={value}
        placeholder={placeHolder}
      >
        <option value="undefined">Select</option>
        {list &&
          list?.map((item) => (
            <option key={item[valueProperty]} value={item[valueProperty]}>
              {item.name}
            </option>
          ))}
      </select>
    </div>
  );
}
