import axios from "axios";

import { LOGIN, NOTIFY_TOKEN_URL } from "./ApiUrls";

export async function Login(formData) {
  const headers = {
    Authorization: "Basic YXBpOkRKQ29ubmVjdGFwaSEh",
    ContentType: "application/x-www-form-urlencoded; charset=utf-8",
  };

  const response = await axios.post(LOGIN, formData, {
    headers,
  });

  if (response && response.data && response.status === 200) {
    return response.data;
  }
  if (response.status === 201) {
    return true;
  }
  return null;
}

export async function postNotificationToken(data) {
  const result = await axios.post(NOTIFY_TOKEN_URL, data);
  return result;
}
