import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import MessageCard from "../components/cards/message-card";
import SearchBox from "../components/layout/search-box";
import HSpacer from "../components/elements/h-spacer";
import VDiv from "../components/containers/vdiv";
import Page from "../components/general/page";
import BgOverlay from "../components/elements/bg-overlay";
import HDiv from "../components/containers/hdiv";
import HGrow from "../components/elements/h-grow";
import Text from "../components/elements/texts/text";
import Services from "../components/sections/services";
import TabBar from "../components/layout/tab-bar";
import { getRoomDetail, getCategories } from "../service/DashboardService";
import Categories from "../components/sections/categories";
import { handleGetFirebaseToken } from "../App";
import { postNotificationToken } from "../service/LoginAPI";
import { AppState } from "../components/state";

function Header({ title, subTitle }) {
  const { appData, setAppData } = useContext(AppState);
  const [message, setMessage] = useState([]);
  useEffect(() => {
    if (appData !== undefined) {
      if (appData.length > 0) {
        setMessage((prevNotifications) => [...prevNotifications, appData]);
      }
    }
    // eslint-disable-next-line
  }, [appData]);

  
  const handleContext = () => {
    setAppData();
    setMessage([]);
  };
  const [notifyStatus, setNotifyStatus] = useState(false);
  const handleNotifyMessage = () => {
    if (notifyStatus === false) {
      document.querySelector(".max").classList.add("show");
      setNotifyStatus(true);
    } else {
      document.querySelector(".max").classList.remove("show");
      setNotifyStatus(false);
    }
  };
  const handleClosePopup = () => {
    document.querySelector(".max").classList.remove("show");
  };
  return (
    <VDiv className="page-header">
      <BgOverlay />
      <HDiv className="brand">
        <img
          className="logo"
          src="../assets/images/conceirge-logo.svg"
          alt="GJ Concierge"
        />
        <VDiv className="welcome-msg grow">
          <Text
            text={title ? title : "Hello User!"}
            size="mg"
            className="user-info white"
          />
          <Text
            text={subTitle ? subTitle : "Welcome to GJ Concierge"}
            size="sm"
            className="brand-info white"
          />
        </VDiv>
        <HGrow />

        <VDiv className={"header-icon-menu"}>
          <VDiv className="menus nav-item dropdown">
            <div
              className="nav-link text-body p-0"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={handleNotifyMessage}
            >
              {message && message.length > 0 ? (
                <div className="notification-icon">
                  <span className="menu-item">
                    <img
                      className="icon image-pop-zoom-up-big"
                      alt="Notifications"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACsElEQVRoQ+2ZgVECMRBFuQrUCoQKlAqECtQKxA6wArEC7UCoQKlArECoQKhAOsD/Z3IzRybJbnI5GGZuZ24YvNzuf9nNJodF58StOHH9nRbg2BlsJAO73a4LsGdcdwbwE58vRVGscwNnBzDifyD03BK7xfd+boisABB/DZHvuPjpsiX++AgIfmaxLAAQ/gA1E1wsHY2xlCYAmWkGh8bUAjDlwhkfJApZmIwQKMmSASCeoj9w2bUeK4Rr4x7ZIEy0JQGYWv/yiGdZUMyyrHUznuuCXenWoZIQw5S1EQ0Q6DJziBhLXcY8/+YASepSKQCcebvmnyCcotQGkDEGv1oPLOBnqHaCgVEACDrCM1y0VYsWXz7sgWCbnWohYgF+4bhbcT5HsHK31cbcGwcI7tLVdcG109c6UwOYhcgdtmo9qeYlIWZNcGKqxh1btdnFAEwQgeeb0mYIwpKqbY4s8NzEeKLFACzg7abiMapWQ0oca+sbAANRPQbEANj1r06zJMRRnmsA9KTneD8GYFd1iADqZzVCAJHkXy0iNYBGPMek+m8BtDMsjWsz0NQMSX7L+20GpJlKnSHJ70Ey4DqFNr0PAEy104tt1HOE3gCgq51dzTjE4QvNmTVWhAgCeMQzBt9heQzOZojFYznfsW0LQngBUh3WIUqZsBDAH8TYvziIKa0DYI4UI3zab31bZPzC5TsEsHe40i6qugA+CF/TUAPk7joSqLZtqwGkgE3fr52BpgVK/lMAXH1ZitPUfe++EyqhAdRMcV02pUrpd4NxI2RgEdWFlM6PPkw8ShxdoSAgG0BgF3VJyLYhZgMwGxB/TbsSJm2Fevb9Cyo64bkBePTgYvNBrHBvAAB2uCyWFaBUZMpphO/lTDMzUwhnV8tqjQBkVXioRXxI0dVYbQaONfNl3H8duC5Ahiw7wwAAAABJRU5ErkJggg=="
                    />
                  </span>
                  <div className="notification-dot"></div>
                </div>
              ) : (
                <span className="menu-item">
                  <img
                    className="icon image-pop-zoom-up-big"
                    alt="Notifications"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAACsElEQVRoQ+2ZgVECMRBFuQrUCoQKlAqECtQKxA6wArEC7UCoQKlArECoQKhAOsD/Z3IzRybJbnI5GGZuZ24YvNzuf9nNJodF58StOHH9nRbg2BlsJAO73a4LsGdcdwbwE58vRVGscwNnBzDifyD03BK7xfd+boisABB/DZHvuPjpsiX++AgIfmaxLAAQ/gA1E1wsHY2xlCYAmWkGh8bUAjDlwhkfJApZmIwQKMmSASCeoj9w2bUeK4Rr4x7ZIEy0JQGYWv/yiGdZUMyyrHUznuuCXenWoZIQw5S1EQ0Q6DJziBhLXcY8/+YASepSKQCcebvmnyCcotQGkDEGv1oPLOBnqHaCgVEACDrCM1y0VYsWXz7sgWCbnWohYgF+4bhbcT5HsHK31cbcGwcI7tLVdcG109c6UwOYhcgdtmo9qeYlIWZNcGKqxh1btdnFAEwQgeeb0mYIwpKqbY4s8NzEeKLFACzg7abiMapWQ0oca+sbAANRPQbEANj1r06zJMRRnmsA9KTneD8GYFd1iADqZzVCAJHkXy0iNYBGPMek+m8BtDMsjWsz0NQMSX7L+20GpJlKnSHJ70Ey4DqFNr0PAEy104tt1HOE3gCgq51dzTjE4QvNmTVWhAgCeMQzBt9heQzOZojFYznfsW0LQngBUh3WIUqZsBDAH8TYvziIKa0DYI4UI3zab31bZPzC5TsEsHe40i6qugA+CF/TUAPk7joSqLZtqwGkgE3fr52BpgVK/lMAXH1ZitPUfe++EyqhAdRMcV02pUrpd4NxI2RgEdWFlM6PPkw8ShxdoSAgG0BgF3VJyLYhZgMwGxB/TbsSJm2Fevb9Cyo64bkBePTgYvNBrHBvAAB2uCyWFaBUZMpphO/lTDMzUwhnV8tqjQBkVXioRXxI0dVYbQaONfNl3H8duC5Ahiw7wwAAAABJRU5ErkJggg=="
                  />
                </span>
              )}
            </div>
          </VDiv>
        </VDiv>
      </HDiv>
      <ul
        className="dropdown-menu  row dropdown-menu-end  gap-3  px-2 py-3 me-sm-n4 h-25vh overflow-auto max h-25vh overflow-auto  z-index-2000 popup-notification"
        aria-labelledby="dropdownMenuButton"
      >
        <div className="d-flex">
          <div>
            {console.log("first", message)}
            {message && message.length > 0 ? (
              message.map((item, index) => (
                <li
                  key={index}
                  className="mb-2 d-flex flex-column"
                  onClick={() => handleContext()}
                >
                  <a
                    className="dropdown-item border-radius-md"
                    href={`#/t/${localStorage.getItem(
                      "tenantId"
                    )}/r/${localStorage.getItem("location")}/service-history`}
                  >
                    <div className="d-flex py-1">
                      <div className="my-auto"></div>
                      <div className="d-flex flex-column justify-content-center">
                        <h6 className="text-sm font-weight-normal mb-1">
                          <span className="font-weight-bold">
                            {item[0].title}
                          </span>{" "}
                        </h6>
                        <p className="text-xs text-secondary mb-0 ">
                          <i className="fa fa-clock me-1"></i>
                          {item[0].data.status}
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
              ))
            ) : (
              <div className="text-center">
                <p>No Notifications</p>
              </div>
            )}
          </div>
          <li onClick={handleClosePopup}>
            <div
              type="button"
              className="close ps-3"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img
                src="../assets/images/concierge-logo.svg"
                className="w-12px"
                alt="close-icon"
              />
            </div>
          </li>
        </div>
      </ul>
    </VDiv>
  );
}

export default function Dashboard() {
  const { tenantId, roomId } = useParams();
  const [loading, setLoading] = useState(false);
  const [headerTitle, setHeaderTitle] = useState();
  const [roomName, setRoomName] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  // eslint-disable-next-line
  const [notifyToken, setNotifyToken] = useState({});
  const [search, setSearch] = useState("");
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const fetchRoomDetails = async () => {
    setLoading(true);
    const response = await getRoomDetail(tenantId, roomId);
    if (response) {
      if (response.location) {
        localStorage.setItem("locationId", response.location.id);
        localStorage.setItem("locationName", response.location.name);
      } else {
        alert("Location not found.");
      }
      setRoomName(response.name);
      localStorage.setItem("roomName", response.name);
      localStorage.setItem("location", response.id);
      setHeaderTitle("Hello!. You are in " + response?.name);
    }
    await fetchCategories();
    setLoading(false);
  };

  const fetchCategories = async () => {
    const response = await getCategories(
      tenantId,
      localStorage.getItem("locationId")
    );
    setCategories(response);
    await handleGetFirebaseToken();
    notifyToken.token = localStorage.getItem("notifyToken");
    notifyToken.userId = localStorage.getItem("location");
    notifyToken.departmentId = localStorage.getItem("locationId");
    // notifyToken.role = "USER";
    notifyToken.deviceId = "";
    postNotificationToken(notifyToken);
  };

  const categoriesWithFilteration = categories?.filter((item) =>
    item?.name?.toLowerCase().includes(search.toLowerCase())
  );

  const fetchServices = (category) => {
    setSelectedCategory(category.id);
  };

  useEffect(() => {
    fetchRoomDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <Page customHeader={<Header title={headerTitle} />} loading={loading}>
      <VDiv>
        <SearchBox handleSearchChange={handleSearchChange} />
        <HSpacer size="lg" />
        <MessageCard
          message={"Hello User!. Get your services done on a tap."}
        />
        <HSpacer size="lg" />
        <Categories list={categoriesWithFilteration} onClick={fetchServices} />
        <HSpacer size="lg" />
        <Services goToDetail roomName={roomName} category={selectedCategory} />
      </VDiv>
      <TabBar />
    </Page>
  );
}
