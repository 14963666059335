import SearchBox from "../components/layout/search-box";
import HSpacer from "../components/elements/h-spacer";
import VDiv from "../components/containers/vdiv";
import Page from "../components/general/page";
import TabBar from "../components/layout/tab-bar";
import ProductsSection from "../components/sections/products-section";
import ProductsHeader from "../components/layout/products-header";
import { useState } from "react";
import ToastMessage from "../components/popups/toast-message";
import useCart from "../hooks/useCart";

export default function Explore() {
  const [search, setSearch] = useState("");

  const {setOrderPlaced} = useCart()
  
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  
  return (
    <>
    <ToastMessage/>
    <Page
      customHeader={
        <ProductsHeader
          title={"You are in '" + localStorage.getItem("roomName") + "'"}
        />
      }
      title={"Products and Packages"}
    >
      <VDiv>
        <SearchBox
          placeholder={"Search products..."}
          handleSearchChange={handleSearchChange}
        />
        <HSpacer size="lg" />
        <ProductsSection search={search} setOrderPlaced={setOrderPlaced} />
      </VDiv>
      <TabBar selectedIndex={3} />
    </Page>
    </>
  );
}
