import React from "react";
import PagePopup from "../containers/page-popup";

export default function FormPopup({
  onSave,
  onCancel,
  children,
  title,
  showNextButton,
  onNextClick,
  data
}) {

  
  return (
    <PagePopup onBack={onCancel} showHeader showBackNavigation title={title}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
         showNextButton? onNextClick(data) :onSave(false);
        }}
      >
        {children}
        <div className="form-footer mt-3">
          <button
            type="button"
            className="mb-3 col-sm-6 btn btn-outline-secondary"
            onClick={() => onCancel()}
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
         {showNextButton?<button type="submit" className="mb-3 col-sm-6 btn btn-primary"      data-bs-toggle={data.phoneNumber ? "modal" : ""} data-bs-target={data.phoneNumber ? "#popup" : ""} >
            Next
          </button>: <button type="submit" className="mb-3 col-sm-6 btn btn-primary">
            Save
          </button>}
        </div>
      </form>
    </PagePopup>
  );
}
