import React from "react";
import HDiv from "../containers/hdiv";

export default function CategoryCard({ item, onClick, selected = false }) {
  return (
    <HDiv
      className={`cursor-pointer edc-card image-pop-zoom-up ${selected ? " selected" : ""}`}
      onClick={onClick}
    >
      <p>{item.name}</p>
    </HDiv>
  );
}
