import {  useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { addToCart, deleteCartItem, getCart, postProductRequest, updateCartItem } from '../service/CartService';
import { message } from '../components/popups/toast-message';

const useCart = () => {

  const [cartItems, setCartItems] = useState({});
  const [orderPlaced, setOrderPlaced] = useState(false);
  const { tenantId,roomId } = useParams();
  const [cartCount,setCartCount] = useState(0)
  const [cartDetails,setCartDetails]= useState()
  const [showForm,setShowForm] = useState(false);
  const [productFormData,setproductFormData] = useState()
  const locationId = localStorage.getItem("locationId")
  
  const addQuantity = (index,quantity) => {
    
    const updatedCart = [...cartItems]; 
    const selectedItem = updatedCart[index];
    
    if (selectedItem) {
      if (quantity > 0 && selectedItem.quantity + 1 <= quantity) {
        selectedItem.quantity += 1;
        setCartItems(updatedCart);
      }else if(quantity === 0 ){
        selectedItem.quantity += 1;
        setCartItems(updatedCart);
      }else if(selectedItem.quantity + 1 > quantity){        
        message("You have reached your stock limit","success")
      }
     
    }
  };

  
  const reduceQuantity = (index) => {
    const updatedCart = [...cartItems];
    const selectedItem = updatedCart[index]
    
    if (selectedItem.quantity >1) {
      selectedItem.quantity -= 1;
      setCartItems(updatedCart);
    }
  };


  const updateCartQuantity  = async(item,type,quantity,index) =>{
     
    
    const updatedCart = [...cartItems]; 
    const selectedItem = updatedCart[index];


    if(type === "ADD"&&quantity > 0 && selectedItem.quantity + 1 > quantity){        
      message("You have reached your stock limit","success")
      return;
    }else if(type === "REDUCE" && selectedItem.quantity === 1){
      return;
    }

    let newQuantity =
    type === "ADD" ? selectedItem.quantity + 1 : selectedItem.quantity - 1;

    let payload = {
      product: { id: item.product.id },
      cart: { id: localStorage.getItem("cartId") },
      quantity: newQuantity
    };
    const response = await updateCartItem(tenantId, payload);
    
    if (response) {
      fetchCart()
      setOrderPlaced(false)
      // document.getElementById("cart-count").innerHTML = response.data.quantity;
    }
  }
  const deleteItem = async(cartItemId) => {
    const res = await deleteCartItem(tenantId,cartItemId)
    if(res.status.message === "Success"){
      fetchCart()
      message("Item deleted succesfully","success")
    }    
  };


  
  const fetchCart = async () => {
    const payload = {
      location: localStorage.getItem("location"),
      locationId: localStorage.getItem("locationId"),
      locationName: localStorage.getItem("roomName"),
    };
    const response = await getCart(
      tenantId,
      localStorage.getItem("locationId"),
      payload
    );


    
    localStorage.setItem("cartId", response?.id);
    sessionStorage.setItem('cartData', JSON.stringify(response?.items));

    const filteredCartData = response?.items?.filter((item)=>item.deleted === false && item.requested === false)
    
    setCartDetails(response)
    // localStorage.setItem("cartId", response.id);
    setCartItems(filteredCartData)
    setCartCount(filteredCartData?.length)
  };




  const openFormPopup = () =>{
    
    setShowForm(true)
  }
  const closeFormPopup = () =>{
    setShowForm(false)
  }
 
  const openCartPopup = (data) =>{   
    fetchCart() 
    setproductFormData(data)
    localStorage.setItem("productData",JSON.stringify(data))
    setShowForm(false)
    const cartIcon = document.getElementById("cart-icon");

    if (cartIcon && data?.phoneNumber) {
      cartIcon.removeAttribute("data-bs-toggle", "modal");
      cartIcon.removeAttribute("data-bs-target", "#popup");
    }
  
  }

  
  const onProceedToOrder = async() => {

  const data = {
    stockdetails: cartItems
    .filter(item => !item.requested) 
    .map(item => ({
      product: {
        id: item.product.id,
        name:item.product.name,
        category: {
          id: ""
        },
        productQuantity: 10
      },
      room: {
        id: roomId
      },
      comment: productFormData?.comment,
      userName: productFormData?.userName,
      email: productFormData?.email,
      quantity: item.quantity,
      cartId: localStorage.getItem("cartId"),
      amount: item.totalAmount,
      phoneNumber: productFormData?.phoneNumber,
      departmentId: item.product.department.id,
      locationId: locationId,
      tenantId: tenantId,
      histories: [
        {
          attachments: []
        }
      ]
    }))
  };
  const res = await postProductRequest(tenantId,roomId,data.stockdetails)
  if(res){
    setproductFormData([])
    localStorage.removeItem("cartId")
    setOrderPlaced(true);
    fetchCart()
    sessionStorage.clear();
    setTimeout(() => {
      setOrderPlaced(false);
    }, 5000);
  
      setCartItems([])

    }     else{
      setOrderPlaced(false)
    }
  }


  const onAddToCart = async (event, item) => {

    const alreadyExistData = cartDetails?.items?.find((cartItem)=>cartItem?.product?.id === item?.id)
    
    // event.stopPropagation();
    let payload = {
      product: { id: item?.id },
      cart: { id: localStorage.getItem("cartId") },
      quantity: 1,
    };
    const response = await addToCart(tenantId, payload);
    
    if (response && response?.quantity) {
      setOrderPlaced(false)
      fetchCart()
      if(alreadyExistData){
        message("Item already exists","success")
      }else{
        message("Added item to cart","success")
      }

      document.getElementById("cart-count").innerHTML = response.quantity;
    }
  };
  
  
    useEffect(() => {
      fetchCart();

      // eslint-disable-next-line
    }, [tenantId]);


  return {
    cartItems,
    addQuantity,
    reduceQuantity,
    deleteItem,
    fetchCart,
    onProceedToOrder,
    orderPlaced,
    cartCount,
    setOrderPlaced,
    onAddToCart,
    cartDetails,
    updateCartQuantity,
    openFormPopup,
    closeFormPopup,
    showForm,
    openCartPopup,
    productFormData,
    
  };
};
export default useCart;
