import React from 'react'


export default function MessageCard({message}) {

    return(
        <div className='message-card'>
            <p className='message'>
            {message ? message : "Hello User!"}
            </p>
        </div>
    )
}