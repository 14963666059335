import { HashRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import ServiceHistory from "./pages/ServiceHistory";
import Explore from "./pages/Explore";
import Chat from "./pages/Chat";
import More from "./pages/More";
import ServiceRequestDetail from "./pages/ServiceRequestDetail";
import { useContext, useEffect, useState } from "react";
import { getFirebaseToken, onForegroundMessage } from "./firebase";
import Toast from "./components/notification-popup/notification-popup"; // toaster,
import { AppState } from "./components/state";
import ProductRequestDetail from "./pages/ProductRequestDetail";

export const handleGetFirebaseToken = () => {
  const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (isiOS === false) {
    if (localStorage.getItem("locationId")) {
      return getFirebaseToken()
        .then((firebaseToken) => {
          localStorage.setItem("notifyToken", firebaseToken);
          return firebaseToken;
        })
        .catch((err) =>
          console.error(
            "An error occurred while retrieving Firebase token. ",
            err
          )
        );
    }
  }
};

function App() {
  const [showToast, setShowToast] = useState(false);
  const { setAppData } = useContext(AppState);
  const [toastbody, setToastBody] = useState("");
  const [toastMsg, setToastMsg] = useState("");
  // const [notificationPermission, setNotificationPermission] = useState(
  //   Notification.permission
  // ); // eslint-disable-next-line
  const [notifications, setNotifications] = useState([]);
  const isiOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const [notificationPermission, setNotificationPermission] = useState(isiOS === false ? Notification.permission : "")

  useEffect(() => {
    if (isiOS === false) {
      if (Notification.permission !== "granted") {
        requestNotificationPermission();
      } else if (Notification.permission === "granted") {
        handleGetFirebaseToken();
      }
    }// eslint-disable-next-line
  }, [notificationPermission]);

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      setNotificationPermission(permission);
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };
  useEffect(() => {
    if (isiOS === false) {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js") // Path to your service worker file
          .then((registration) => { })
          .catch((error) => {
            console.error("Error registering service worker:", error);
          });
      }
      navigator.serviceWorker.addEventListener("message", handleMessage);
    }
    // eslint-disable-next-line
  }, []);
  const handleMessage = (event) => {
    if (event.data && event.data.type === "background-message") {
      const { title, body, data } = event.data;
      const newNotification = { title, body, data };

      if (data.roomId === localStorage.getItem("location")) {
        setNotifications([newNotification]);
      }
    }
  };

  useEffect(() => {
    if (isiOS === false) {
      setAppData(notifications);
    }
    // eslint-disable-next-line
  }, [notifications]);
  useEffect(() => {
    if (isiOS === false) {
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    }// eslint-disable-next-line
  }, [showToast]);
  useEffect(() => {
    if (isiOS === false) {
      try {
        onForegroundMessage((payload) => {
          const {
            notification: { title },
            data,
          } = payload;

          setToastMsg(title);
          setToastBody(
            data?.status
              ? `Your request is  ${data.status} `
              : "Your Service request is being Looked"
          );
          setShowToast(true);
        });
      } catch (err) {
        console.error(
          "An error occurred while setting up foreground message listener. ",
          err
        );
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showToast && <Toast ToastMsg={toastMsg} ToastBody={toastbody} />}
      <HashRouter>
        <Routes>
          <Route element={<Dashboard />} path="/t/:tenantId/r/:roomId" />
          <Route
            element={<ServiceHistory />}
            path="/t/:tenantId/r/:roomId/service-history"
          />
          <Route element={<Explore />} path="/t/:tenantId/r/:roomId/explore" />
          <Route element={<Chat />} path="/t/:tenantId/r/:roomId/chat" />
          <Route element={<More />} path="/t/:tenantId/r/:roomId/more" />
          <Route
            element={<ServiceRequestDetail />}
            path="/t/:tenantId/r/:roomId/service-request/:id"
          />
          <Route
            element={<ProductRequestDetail />}
            path="/t/:tenantId/r/:roomId/product-request/:id"
          />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
