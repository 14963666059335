import { useState } from "react";

const useProductRequestPopup = (formData) => {
  const [data, setData] = useState({ ...formData });

  const handleInputChange = (event) => {
    event.stopPropagation();
    if (event.target.name === "service") {
      setData((state) => ({
        ...state,
        service: { id: event.target.value },
      }));
      return;
    }
    setData((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    data,
    handleInputChange,
  };
};

export default useProductRequestPopup;
