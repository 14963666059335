import React from 'react'
import BackButton from '../elements/back-btn'
import HGrow from '../elements/h-grow'
import VDiv from '../containers/vdiv'
import HDiv from '../containers/hdiv'
import PageSubTitle from '../elements/texts/page-sub-title'
import PageTitle from '../elements/texts/page-title'

export default function PagePopupHeader({showBackNavigation, onBack, title, subTitle, children, goToPrevPage}) {
    return (
        <HDiv className="popup-header">
            {showBackNavigation ? <BackButton onBack={onBack} goToPrevPage = {goToPrevPage} /> : null}
            <VDiv className='ml-3'>
                {title ? <PageTitle text={title} className="white" /> : null}
                {subTitle ? <PageSubTitle text={subTitle}/> : null}
            </VDiv>
            <HGrow />
            {children ? children : null}
        </HDiv>
    )
}