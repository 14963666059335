import React from 'react'
import VDiv from '../containers/vdiv'
import PageHeader from './page-header'
import Loader from './loader'

export default function Page({showHeader, showBackNavigation, title, subTitle, onBack, children, customHeader, className, loading = false}) {

    return (
        <VDiv className="page">
            {showHeader ? <PageHeader title={title} subTitle={subTitle} showBackNavigation={showBackNavigation} onBack={onBack} /> : null}
            {customHeader ? customHeader : null}
            <VDiv className={`page-content ${className ? className : ''}`} >
                {loading ? <Loader /> : <>{children}</>}
            </VDiv>
        </VDiv>
    )
}