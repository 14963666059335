import { useNavigate } from "react-router-dom";

export default function useBackButton() {
  const navigate = useNavigate();

  const handleBackClick = (callback, goToPrevPage) => {
    if (callback) {
      callback();
    }
    if (goToPrevPage) {
      navigate(-1);
    }
  };

  return { handleBackClick };
}
