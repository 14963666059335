
import VDiv from "../components/containers/vdiv";
import Page from "../components/general/page";
import ServiceRequestDetailSection from "../components/sections/service-request-detail-section";

export default function ServiceRequestDetail() {  
    return (
      <Page showHeader title={"Service request detail"} showBackNavigation={true} >
        <VDiv>
          <ServiceRequestDetailSection />
        </VDiv>
      </Page>
    );
  }