import React from 'react'
import SectionHeader from '../elements/section-header'

export default function EdcCarousel({title, showViewAll, onViewAll, children}) {
    return(
        <div className='edc-carousel'>
            <SectionHeader title={title} showViewAll={showViewAll} onViewAll={onViewAll} />
            <div className='flex no-shrink scroll-x'>
                <div className='edc-carousel-content'>
                    {children}
                </div>
            </div>
        </div>
    )
}